import {Link} from 'gatsby';
import React from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from '../../components/layout';
// import './styles.scss';

const PrivacyPolicy = () => {
  const {t, i18n} = useTranslation();
  return (
    <Layout
      title={'Privacy Policy | Candidate Checker'}
      metaName={'description'}
      metaContent={'This Privacy Policy applies to personal information collected by Hexact when you use our Site and/or Services, or otherwise provide us with personal information. '}
      small
      showLogo
    >
      <div className="page-hero">
        <h1 className="page-title">Privacy Policy</h1>
      </div>
      <div dir="ltr">
        <p>
          Welcome to{' '}
          <Link className="clickable-text" to="/">
            CandidateChecker.io
          </Link>{' '}
          (“Site”), hosted by Hexact, Inc. (“Hexact”, “we”, “us” and/or “our”). Hexact provides SaaS (software as a
          service) to individuals and businesses (“Services”). In order to provide our Site and Services, we collect
          personal data from our Site visitors (“Site Visitors”) and our customers (“Customers”). We also collect the
          personal data of our Customers’ end users (“End Users”) when they use the Services, namely our Customer’s
          employees, consultants, or any other persons to whom access is granted by the Customer.
        </p>
        <p>
          This Privacy Policy applies to personal information collected by Hexact when you use our Site and/or Services,
          or otherwise provide us with personal information. Please read it carefully to understand our policies and
          practices regarding your personal information and how we will treat it. If you do not agree with our Privacy
          Policy, please do not download, install, register with, access, or use the Site or Services.
        </p>
        <p>
          <strong>IF YOU ARE AN INDIVIDUAL LOCATED IN THE EEA:</strong> If you are located in the European Economic Area
          (“EEA”), this entire Privacy Policy applies to you. However, please see the section titled{' '}
          <Link
            className="clickable-text"
            to="/privacy-policy#additional-information-for-users-in-the-eea-and-the-u.k."
          >
            Additional Information for Users in the EEA
          </Link>
          , which will inform you in detail about our legal bases for processing and your rights regarding the
          processing of your personal data.
        </p>
        <p>
          <strong>IF YOU ARE A RESIDENT OF NEVADA:</strong> If you are a resident of Nevada, this entire Privacy Policy
          applies to you. However, please see the section titled{' '}
          <Link className="clickable-text" to="/privacy-policy#notice-to-nevada-consumers">
            Notice to Nevada Consumers
          </Link>
          , which will also apply to you.
        </p>
        <p className="bold primary-text">WHO WE ARE</p>
        <div className="py-2 text-left">
          <div className="text-left">Hexact is a Delaware corporation with the following contact information:</div>
          <div className="text-left">Hexact, Inc.</div>
          <div className="text-left">1250 E. Hallandale Beach Blvd., Unit 808, Hallandale Beach, Florida 33009</div>
          <a className={`text-left clickable-text`} href="mailto:admin@hexact.io">
            admin@hexact.io
          </a>
        </div>

        <p>For users in the EEA and the U.K., note that we may collect your personal data as:</p>

        <ul>
          <li>
            A “data controller” when we determine the means and purpose of processing, such as when we process the
            personal data of our Site visitors and/or customers, or as
          </li>
          <li>
            A “data processor” when we collect and process End User personal data on behalf of our Customers who use our
            Services.
          </li>
        </ul>
        <p>
          When we act as a ‘data processor’, our Customers are primarily responsible for making sure that they have
          properly informed End Users of their policies and practices and your rights. However, Hexact handles and
          secures your personal information as set forth in this Privacy Policy (except as noted otherwise in this
          Privacy Policy).
        </p>
        <p className="bold primary-text">CHILDREN’S PRIVACY</p>
        <p>
          Hexact does not knowingly collect information from children under the age of 16. If you are under the age of
          16, please do not submit any personal data to us. We encourage parents and legal guardians to monitor their
          children’s Internet usage and to help enforce our Privacy Policy by instructing their children never to
          provide personal data without their permission. If you have reason to believe that a child under the age of 16
          has provided personal data to Hexact through the Site or Services, please contact{' '}
          <a href="mailto:admin@hexact.io" className="clickable-text" target="_blank" rel='noopener'>
            admin@hexact.io
          </a>{' '}
          and we will endeavor to delete that information from our databases.
        </p>
        <p className="bold primary-text">CHANGES TO THIS PRIVACY POLICY</p>
        <p>
          This Privacy Policy was last updated on the date indicated above, but we suggest that you review it from time
          to time, as our Site and/or Services and our business may change. As a result, at times it may be necessary
          for Hexact to make changes to this Privacy Policy. Hexact reserves the right to update or modify this Privacy
          Policy at any time and from time to time without prior notice. However, if we make changes that we believe
          will materially impact this Privacy Policy or your rights, we will promptly notify you of those changes. Your
          continued use of the Site and/or Services after any changes or revisions to this Privacy Policy shall indicate
          your agreement with the terms of such revised Privacy Policy.
        </p>
        <p className="bold primary-text">TO WHOM DOES THIS POLICY APPLY</p>
        <p>
          Note at the outset that this Privacy Policy does not cover our Customers’ websites, products or services. Each
          Customer is responsible for posting its own terms, conditions, and privacy policies, and ensuring compliance
          with all applicable laws and regulations. This Privacy Policy applies to:
        </p>
        <ul>
          <li>
            <strong>Customers:</strong> as noted above, this includes any individual who registers or creates an account
            individually or on behalf of an entity or organization in order to use the Services.
          </li>
          <li>
            <strong>Site Visitors:</strong> visitors to our Site, including those who may also opt-in to receive
            commercial communications from Hexact.
          </li>
          <li>
            <strong>End Users:</strong> Hexact processes End User data on behalf of its Customers. While our Customers
            are responsible, as data controllers, for how and why they collect and process their End User personal
            information, this Privacy Policy also applies to any End User personal information that we process, as a
            data processor, in order to provide Services to our Customers, except where specifically indicated.
          </li>
        </ul>
        <p className="bold primary-text">INFORMATION WE COLLECT</p>
        <p>
          What personal information we collect and process depends on how and why you use our Site or Services.
          Generally, we process personal information that we receive:
        </p>

        <ul>
          <li>Directly from you when you provide it to us, such as in connection with our Services.</li>
          <li>Indirectly, through automated technologies such as cookies, or from third parties.</li>
        </ul>
        <p>
          <small>Information We Collect Directly From You</small>
        </p>
        <p>
          You can generally visit our Site without having to submit any personal information. If you request more
          information, or sign up for our Services, we will collect personal information as follows.
        </p>
        <p>
          <em>Contact Forms</em>
        </p>
        <p>
          If you contact us via the contact form on our Site, we will ask you to provide information (e.g. your name,
          email address, company name, title).
        </p>
        <p>
          <em>Account Information</em>
        </p>
        <p>
          When you register for a Customer account (including when you join for free) we request your email address. For
          corporate Customers with multiple team members, we may also ask you to submit your name and company name, as
          well as team members who will have access to Hexact. This information is your “Account Information” for the
          purposes of this Privacy Policy. Account Information is required to identify you as a Customer and permit you
          to access your account(s).
        </p>
        <p>
          Note that our corporate Customers are responsible for ensuring that they comply with applicable privacy laws
          and notice requirements with respect to any individual whose name and information is submitted in connection
          with the Account Information.
        </p>
        <p>
          <em>Customer Payment Information</em>
        </p>
        <p>
          You are not required to enter your credit card information unless and until you decide to continue with a paid
          subscription to our Services. In order to process your payment Information, we use PCI-compliant third-party
          processors, as explained in the section on
          <Link className="clickable-text" to="/privacy-policy#payment-processing">
            Payment Processing
          </Link>
          below. This information is processed by our payment service provider and we receive a confirmation of payment,
          which we then associate with your Account Information and any relevant transactions. In case of corporate
          Customers, other payment methods (e.g wire transfer) may be availed to you.
        </p>
        <p>
          <em>Optional Information</em>
        </p>
        <p>
          We may also ask you to submit personal information if you choose to use interactive features of the Site
          and/or Services, including participation in surveys, promotions, requesting customer support, or otherwise
          communicating with us. We may also ask you for information when you interact with us (such as when responding
          to notices and announcements from us), and when you report a problem with Hexact and/or the Site or otherwise
          correspond with us. This includes:
        </p>
        <ul>
          <li>Records and copies of your correspondence (including email addresses), if you contact us</li>
          <li>Your responses to surveys that we might ask you to complete for research purposes</li>
        </ul>
        <p>
          <small>Information We Collect Indirectly</small>
        </p>
        <p>
          <em>Device and Usage Information</em>
        </p>

        <p>
          When you download, use or interact with the Site, even if you do not have an account, we, or authorized third
          parties engaged by us, may automatically collect information about your use of the Site via your device. This
          information is collected via cookies and similar technologies (“Device and Usage Information”) and consists
          of:
        </p>
        <ul>
          <li>
            <strong>Information About your Device:</strong> information about the devices and software you use to access
            the Site — primarily the internet browser or mobile device that you use, the website or source that linked
            or referred you to the Site, your IP address or device ID (or other persistent identifier that uniquely
            identifies your computer or mobile device on the Internet), the operating system of your computer or mobile
            device, device screen size, and other similar technical information.
          </li>
          <li>
            <strong>Usage Information:</strong> information about your interactions with the Site, including access
            dates and times, hardware and software information, device event information, log data, crash data, cookie
            data, and search queries on the Site and/or Services. This information allows us to understand the screens
            that you view, how you’ve used the Site and/or Services (which may include administrative and support), and
            other actions on the Site. We, or authorized third parties, automatically collect log data when you access
            and use the Site, even if you have not created an account or logged in. We use this information to
            administer and improve the Site and/or Services, analyze trends, track users’ use of the Site, and for
            remarketing purposes, as explained here.
          </li>

          <li>
            <strong>Location Information:</strong> based on Device and Usage Information, we are also able to determine
            general location information, but we do not store IP addresses.
          </li>
        </ul>
        <p>
          <em>Information from Third Parties</em>
        </p>
        <p>
          In some instances, we process personal information from third parties. This consists of data from our
          partners, such as transactional data from providers of payment services, or information from third parties who
          assist us with fraud prevention. From time to time, we may combine information we collect as described above
          with personal information we obtain from third parties. For example, we may combine information entered
          through a Hexact sales submission with information that we receive from a third-party sales intelligence
          platform to enhance our ability to market our Services to Customers or potential Customers.
        </p>
        <p>
          <small>Information We Process on Behalf of Our Customers</small>
        </p>
        <p>
          As noted above, we will process Account Information in order to provide the Services to our Customers. This
          includes End User information, in order to enable End Users to access and use the Services, and consists of
          name.
        </p>
        <p className="bold primary-text">REMARKETING TAGS</p>
        <p>
          This Site uses Google, Twitter, Linkedin & Facebook remarketing services or tags in order to advertise to
          previous visitors to our Site on third-party platforms such as those listed above. With the help of cookies or
          tags, these remarketing services allow us to advertise our Site to visitors who may have visited our Site.
          This could be in the form of an advertisement on the Google search results page, a site in the Google Display
          Network, or somewhere on Facebook, Linkedin or Twitter.
        </p>

        <p>
          Third-party vendors, including Google, Facebook, Linkedin and Twitter, use cookies (or similar technologies)
          to serve ads based on someone’s past activity on the Site, and as such, your personal information may be
          collected and used by those third-party vendors, subject to their respective privacy policies. It is your
          responsibility to read through their respective policies.
        </p>
        <p>You can opt-out of remarketing by visiting the links below:</p>
        <ul>
          <li>
            <a href="https://support.google.com/adsense/answer/142293?hl=en" target="_blank" className="clickable-text" rel='noopener'>
              Google
            </a>
          </li>
          <li>
            <a
              href="https://m.facebook.com/login.php?next=https%3A%2F%2Fm.facebook.com%2Fsettings%2F%3Ftab%3Dads%26refsrc%3Dhttps%253A%252F%252Fm.facebook.com%252Fads%252Fwebsite_custom_audiences%252F&refsrc=https%3A%2F%2Fm.facebook.com%2Fads%2Fwebsite_custom_audiences%2F&_rdr"
              target="_blank"
              className="clickable-text"
              rel='noopener'
            >
              Facebook
            </a>
          </li>
          <li>
            <a
              href="https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads"
              target="_blank"
              className="clickable-text"
              rel='noopener'
            >
              Twitter
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/help/linkedin/answer/62931/manage-advertising-preferences?lang=en"
              target="_blank"
              className="clickable-text"
              rel='noopener'
            >
              LinkedIn
            </a>
          </li>
        </ul>
        <p className="bold primary-text" id="payment-processing">
          PAYMENT PROCESSING
        </p>
        <p>
          We do not directly collect your payment information and we do not store your payment information. We use
          third-party, PCI-compliant, payment processors, which collect payment information on our behalf in order to
          complete transactions. While our administrators are able to view and track actual transactions via customer
          portals, we do not have access to, or process, your credit card information. In case of corporate Customers,
          if we availed other payment methods, we may request your bank information to process refunds, if any.
        </p>

        <p className="bold primary-text">ANALYTICS</p>
        <p>
          <small>Google Analytics</small>
        </p>
        <p>
          The Site uses Google Analytics, an analytics service that drops cookies and/or similar technologies to collect
          and store Device and Usage Information. We use Google Analytics to calculate visitor, session and campaign
          data for the Site analytics reports.
        </p>
        <p>
          You can read Google’s privacy policy{' '}
          <a href="https://policies.google.com/privacy?hl=en-US" target="_blank" className="clickable-text" rel='noopener'>
            here
          </a>
          . You can opt-out from being tracked by Google Analytics in a particular browser on a particular device by
          downloading and installing the Google Analytics Opt-out Browser Add-on for that browser, which is available{' '}
          <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" className="clickable-text" rel='noopener'>
            here
          </a>
          .
        </p>
        <p>
          <small>Behavioral Analytics</small>
        </p>
        <p>
          We use third-party in-Site behavior analytics platforms (such as Hotjar), which are designed to give us an
          aggregated view of our visitors’ behavior while browsing the Site. By using heat maps (a graphical
          representation of data that uses a system of color-coding to represent different values) and similar
          technologies, these services provide us valuable insight about what is of interest to visitors on our Site.
          Hotjar is not designed to track individual users, however if you wish to opt-out, please click{' '}
          <a href="https://www.hotjar.com/policies/do-not-track/" target="_blank" className="clickable-text" rel='noopener'>
            here
          </a>
          .
        </p>
        {/* <p>
        Please see more on our use of analytics data <a className="https://krisp.ai/security/#customer-data"></a>here.
      </p> */}
        <p className="bold primary-text">VIDEOS</p>
        <p>
          Our Site may contain videos or links to videos relating to the Services. If you click on a link or view a
          video, we do not collect any information, however the third-party video platforms, such as YouTube, may
          collect some personal information as set forth in their privacy notices.
        </p>
        <p className="bold primary-text">HOW & WHY WE USE PERSONAL INFORMATION</p>
        <p>We use your personal information for a number of different reasons, as further explained below.</p>
        <p>
          For users located in the EEA and the U.K., we must have a valid legal basis in order to process your personal
          data when we are acting as a ‘data controller’. The main legal bases under the European Union’s General Data
          Protection Regulation (GDPR) that justify our collection and use of your personal information are:
        </p>
        <ul>
          <li>
            <strong>Performance of a contract:</strong> when your personal information is required in order to enter
            into or perform our contract with you, such as when you engage us to provide our Services
          </li>
          <li>
            <strong>Consent:</strong> when you have consented to our use of your personal information via a consent form
            (online or offline)
          </li>
          <li>
            <strong>Legitimate interests:</strong> when we use your personal information to achieve a legitimate
            interest and our reasons for using it outweigh any prejudice to your data protection rights
          </li>
          <li>
            <strong>Legal obligation:</strong> when we must use your personal information to comply with our legal
            obligations
          </li>
          <li>
            <strong>Legal claims:</strong> when your personal information is necessary for us to defend, prosecute or
            make a claim
          </li>
        </ul>
        <p>
          Below are the general purposes and corresponding legal bases (in brackets) for which we may use your personal
          information:
        </p>
        <ul>
          <li>
            Providing you access to and use of the Site and Services, including accessing content, features and
            functionality <em>(depending on the context, performance of a contract or leg itimate interests)</em>
          </li>
          <li>
            Providing the Services and creating accounts <em>(performance of a contract)</em>
          </li>
          <li>
            Processing and completing transactions, including verifying payments, and sending you related information,
            including purchase confirmations and invoices and important notices
            <em>(depending on the context, performance of a contract or legitimate interests)</em>
          </li>
          <li>
            Developing and improving the Site, Services and user experience <em>(legitimate interests)</em>Responding to
            your queries and requests, or otherwise communicating directly with you such as to give you notices about
            your account{' '}
            <em>
              (depending on the context, performance of a contract, legitimate interests, and in some cases, legal
              claims)
            </em>
          </li>
          <li>
            Improving the content and general administration of the Site, including system maintenance and upgrades,
            enabling new features and enhancing both Site Visitor and Customer experience{' '}
            <em>(legitimate interests)</em>
          </li>
          <li>
            Detecting fraud, illegal activities or security breaches <em>(legitimate interests)</em>
          </li>
          <li>
            Providing our Services to our Customers{' '}
            <em>(depending on the context, performance of a contract or legitimate interests)</em>
          </li>
          <li>
            Ensuring compliance with applicable laws <em>(compliance with a legal obligation)</em>
          </li>
          <li>
            Conducting statistical analyses and analytics by monitoring and analyzing trends, usage, and activities on
            the Site <em>(consent where required (e.g. 3rd-party cookies), or legitimate interests)</em>
          </li>
          <li>
            Customizing our Services and the Site experience according to your individual interests, such as through
            storing information about your preferences and recognizing you when you use or access the Site or Services{' '}
            <em>(legitimate interests)</em>
          </li>
          <li>
            Managing our relationship with you, including Customer service or feedback{' '}
            <em>(legitimate interests or performance of a contract)</em>
          </li>
          <li>
            Send you related information, such as updates, security alerts, and support messages{' '}
            <em>(legitimate interests)</em>
          </li>
          <li>
            Increasing the number of customers who use our Site and Services through marketing and advertising{' '}
            <em>(consent where required, or legitimate interests)</em>
          </li>
          <li>
            Sending commercial communications, in line with your communication preferences, about products and services,
            features, newsletters, offers, promotions, and events{' '}
            <em>(consent and in some cases, depending on location, with existing customers, legitimate interests)</em>
          </li>
          <li>
            Providing social features as part of the Site and Services <em>(legitimate interests)</em>
          </li>
          <li>
            Carrying out our obligations and enforcing our rights arising from any contracts entered into between you
            and us, including for billing and collection{' '}
            <em>(depending on the context, performance of a contract or legal claims)</em>
          </li>
          <li>
            Providing information to regulatory bodies when legally required, and only as outlined below in this Privacy
            Policy <em>(legal obligation, legal claims, legitimate interests)</em>
          </li>
        </ul>
        <p className="bold primary-text">DISCLOSURE OF YOUR INFORMATION</p>
        <p>We only disclose your personal information as described below.</p>
        <p>
          <em>Third-Party Service Providers</em>
        </p>
        <p>
          Hexact discloses personal information to our third party agents, contractors, or service providers who are
          hired to perform services on our behalf. These companies do things to help us provide the Site and/or
          Services, and in some cases collect information directly, for example as explained in Payment Processing
          above. Below is an illustrative list of functions for which we may use third-party service providers:
        </p>
        <ul>
          <li>Hosting and content delivery network services</li>
          <li>Analytics services</li>
          <li>Marketing and social media partners</li>
          <li>Customer support services</li>
          <li>Payment processors</li>
          <li>Communication platforms</li>
          <li>Functionality and debugging services</li>
          <li>Professional service providers, such as auditors, lawyers, consultants, accountants and insurers</li>
        </ul>
        <p>
          <em>Business Transfers and Transactions</em>
        </p>
        <p>
          As we continue to grow, we may purchase websites, applications, subsidiaries, other businesses or business
          units. Alternatively, we may sell businesses or business units, merge with other entities and/or sell assets
          or stock or receive financing, in some cases as part of a reorganization or liquidation in bankruptcy. In
          order to evaluate or as part of these transactions, we may transfer your personal information to a successor
          entity upon a merger, consolidation or other corporate reorganization in which Hexact participates, to a
          purchaser or acquirer of all or a portion of Hexact’s assets, bankruptcy included, or to an investor.
        </p>
        <p>
          <em>Customer</em>
        </p>
        <p>
          When we act on behalf of our Customers (as a data processor or service provider), we may provide End Users’
          personal information to our Customers in order to comply with their requests, End Users’ requests and/or
          regulator requests, among others. Occasionally, we will provide our Customers with aggregated information that
          does not identify End Users directly, in order to provide information about usage, demographics (such as
          location) or other general information.
        </p>

        <p>
          <em>Legal Obligations and Security</em>
        </p>
        <p>
          In addition, Hexact will preserve or disclose your personal information in limited circumstances (other than
          as set forth in this Privacy Policy), including: (i) with your consent; (ii) when we have a good faith belief
          it is required by law, such as pursuant to a subpoena, warrant or other judicial or administrative order (as
          further explained below); (iii) to protect the safety of any person and to protect the safety or security of
          our Site and/or Services or to prevent spam, abuse, or other malicious activity of actors with respect to the
          Site and/or Services; or (iv) to protect our rights or property or the rights or property of those who use the
          Site and/or Services. If we are required to disclose personal information by law, such as pursuant to a
          subpoena, warrant or other judicial or administrative order, our policy is to only respond to requests that
          are properly issued by law enforcement within the United States or via mutual legal assistance mechanism (such
          as a treaty) in accordance with applicable laws.
        </p>

        <p className="bold primary-text">DATA SECURITY</p>
        <p>
          We respect and are committed to safeguarding your privacy and have undertaken and put in place reasonable
          security measures.
        </p>
        <p className="bold primary-text">“DO NOT TRACK”</p>
        <p>
          Hexact does not respond to Do Not Track (“DNT”) browser signals. For more information on DNT settings
          generally, please visit{' '}
          <a href="https://allaboutdnt.com" target="_blank" className="clickable-text" rel='noopener'>
            https://allaboutdnt.com
          </a>
          .
        </p>
        <p className="bold primary-text">HOW LONG DO WE KEEP YOUR PERSONAL INFORMATION?</p>
        <p>
          <em>General Retention Periods</em>
        </p>
        <p>We use the following criteria to determine our retention periods:</p>
        <ul>
          <li>The amount, nature and sensitivity of your information</li>
          <li>The reasons for which we collect and process the personal data</li>
          <li>
            The length of time we have an ongoing relationship with you and provide you with access to our Site and/or
            Services
          </li>
          <li>Applicable legal requirements</li>
        </ul>
        <p>
          We retain personal information for as long as needed to provide our Services. Note, however, that with respect
          to our Customers with active accounts, we may retain certain essential account information, but otherwise
          regularly delete other information that is less essential to the provision of our Services in order to
          minimize our storage of data. We also will retain personal information that we’ve collected from you where we
          have an ongoing legitimate business need to do so (for example, to comply with applicable legal, tax or
          accounting requirements). Additionally, we cannot delete information when it is needed for the establishment,
          exercise or defense of legal claims (also known as a “litigation hold”). In this case, the information must be
          retained as long as needed for exercising respective potential legal claims. When we no longer have an ongoing
          legitimate business need to process your personal information, we will either delete or anonymize it or, if
          this is not possible (for example, because your personal information has been stored in backup archives), we
          will securely store your personal information and isolate it from any further processing until deletion is
          possible. For any questions about data retention, please contact{' '}
          <a href="mailto:admin@hexact.io" className="clickable-text" target="_blank" rel='noopener'>
            admin@hexact.io
          </a>
          .
        </p>
        <p>
          <em>Anonymization</em>
        </p>
        <p>
          In some instances, we may choose to anonymize your personal data instead of deleting it, for statistical use,
          for instance. When we choose to anonymize, we make sure that there is no way that the personal data can be
          linked back to you or any specific user.
        </p>
        <p className="bold primary-text">OPTING-OUT OF MARKETING</p>
        <p>
          You may opt-out at any time of marketing that we may send you by clicking on the unsubscribe link contained in
          each email, or you may contact us directly at{' '}
          <a href="mailto:admin@hexact.io" className="clickable-text" target="_blank" rel='noopener'>
            admin@hexact.io
          </a>
          .
        </p>
        <p className="bold primary-text" id="notice-to-nevada-consumers">
          NOTICE TO NEVADA CONSUMERS
        </p>
        <p>
          We do not sell your personal information within the scope of, and according to the defined meaning of, a
          “sale” under NRS 603A.
        </p>
        <p className="bold primary-text">INTERNATIONAL DATA TRANSFERS</p>
        <p>
          Hexact is a United States corporation, which primarily stores information in the United States. To facilitate
          our global operations, we may process personal information from around the world, including from other
          countries and in other countries in which Hexact has operations, in order to provide the Site and/or Services.
        </p>
        <p className="bold">
          If you are accessing or using our Site and/or Services or otherwise providing personal information to us, you
          are agreeing and consenting to the processing of your personal information in the United States and other
          jurisdictions in which we operate.
        </p>

        <p>
          If you are a Customer, you are responsible for informing your End Users of how and where their personal
          information will be processed at the time of collection. Because different countries may have different data
          protection laws than the United States we take steps to ensure adequate safeguards are in place to protect
          your data as explained in this Privacy Policy. We enter into data processing agreements with our Customers on
          request.
        </p>
        <p className="bold primary-text" id="additional-information-for-users-in-the-eea-and-the-u.k.">
          ADDITIONAL INFORMATION FOR USERS IN THE EEA AND THE U.K.
        </p>
        <p>
          <small>Rights and Choices</small>
        </p>
        <p>
          If the GDPR applies to you because you are in the EEA or the U.K., you have certain rights in relation to your
          personal data:
        </p>
        <ul>
          <li>
            The right to be informed: our obligation to inform you that we process your personal data (and that’s what
            we’re doing in this Privacy Policy)
          </li>
          <li>
            The right of access: your right to request a copy of the personal data we hold about you (also known as a
            ‘data subject access request’)
          </li>
          <li>
            The right of rectification: your right to request that we correct personal data about you if it is
            incomplete or inaccurate (though we generally recommend first making any changes in your Account Settings)
          </li>
          <li>
            The right to erasure (also known as the ‘right to be forgotten’): under certain circumstances, you may ask
            us to delete the personal data we have about you (unless it remains necessary for us to continue processing
            your personal data for a legitimate business need or to comply with a legal obligation as permitted under
            the GDPR, in which case we will inform you)
          </li>
          <li>
            The right to restrict processing: your right, under certain circumstances, to ask us to suspend our
            processing of your personal data
          </li>
          <li>
            The right to data portability: your right to ask us for a copy of your personal data in a common format (for
            example, a .csv file)
          </li>
          <li>
            The right to object: your right to object to us processing your personal data (for example, if you object to
            us processing your data for direct marketing)
          </li>
          <li>
            Rights in relation to automated decision-making and profiling: our obligation to be transparent about any
            profiling we do, or any automated decision-making. These rights are subject to certain rules around when you
            can exercise them.
          </li>
        </ul>
        <p>
          How you may exercise these rights depends on how you use the Site and/or Services, as explained below. For End
          Users in the EEA or the U.K., please read below.
        </p>
        <p>
          <em>Customers, Site Visitors in the EEA or the U.K</em>
        </p>
        <p>
          If you are located in the EEA or the U.K. and you are a Customer or Site Visitor, and wish to exercise any of
          the rights set out above, you may contact us at{' '}
          <a href="mailto:admin@hexact.io" className="clickable-text" target="_blank" rel='noopener'>
            admin@hexact.io
          </a>{' '}
          using the term “DSR” as your email subject line. You will not have to pay a fee to access your personal data
          (or to exercise any of the other rights) unless your request is clearly unfounded, repetitive or excessive.
          Alternatively, we may refuse to comply with your request under those circumstances. If we cannot reasonably
          verify your identity, we will not be able to comply with your request(s). We may need to request specific
          information from you to help us confirm your identity. This is a security measure to ensure that personal data
          is not disclosed to any person who has no right to receive it. Note that this is especially true when you
          engage a third party to assist you in exercising your rights. We will respond to all legitimate requests
          within one month. Occasionally it may take us longer than a month if your request is particularly complex or
          you have made a number of requests. In this case, we will notify you and keep you updated as required by law.
          In addition, we will always balance your rights against those of other data subjects in connection with any
          requests, and in some cases, this may require us to redact our responses or deny a request.
        </p>
        <p>
          If you no longer wish to receive our marketing/promotional information, we remind you that you may withdraw
          your consent to direct marketing at any time directly from the unsubscribe link included in each electronic
          marketing message we send to you. If you do so, we will promptly update our databases, and will take all
          reasonable steps to meet your request at the earliest possible opportunity, but we may continue to contact you
          to the extent necessary for the purposes of providing our Services.
        </p>
        <p>
          Finally, you have the right to make a complaint at any time to the supervisory authority for data protection
          issues in your country of residence. We would, however, appreciate the chance to address your concerns before
          you approach the supervisory authority, so please contact us directly first.
        </p>
        <p>
          <em>End Users in the EEA or the U.K.</em>
        </p>
        <p>
          Hexact has no direct relationship with End Users. Our Customers are solely responsible for ensuring compliance
          with all applicable laws and regulations with respect to their End Users, and this includes handling all data
          subject requests. We rely on our Customers to comply with the underlying legal requirements and respond
          directly to End Users when End Users wish to exercise the rights set forth above. However, if an End User
          sends a request to Hexact to access, correct, update, or delete his/her information, we will direct that End
          User to contact the Customer’s website(s) with which he/she interacted directly, and cooperate with our
          Customers as required by applicable law in order to ensure that our Customers satisfy their End Users’
          requests.
        </p>
        <p className="bold primary-text">CONTACT US</p>
        <p>
          If you have questions about data protection, or if you have any requests for resolving issues with your
          personal data, we encourage you to first contact us so we can reply to you more quickly.
        </p>
        <div className="text-left">Hexact, Inc.</div>
        <div className="text-left">1250 E. Hallandale Beach Blvd., Unit 808, Hallandale Beach, Florida 33009</div>
        <div className="text-left">admin@hexact.io</div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
